import axios from 'axios'

import { APP_TITLE, APP_VERSION } from '@/init/settings'
import { AuthUser } from '@/models/interfaces'
import { auth } from '@/services/auth'
import { request } from '@/services/request'
import { waitWithTimeout } from '@/helpers/timer'
import { Sentry } from '@/services/sentry'

import { isBot } from '@/helpers/tracking'
import { storage } from '@/helpers/storage'
import { experimentsData } from '@/services/ab'

export const FIRST_PAGE_KEY = 'sf_first_page'
export const MARKETING_URL_KEY = 'sf_marketing_url'
export const HISTORY_KEY = 'sf_history'
export const REF_KEY = 'sf_referrer'
export const REF_DOMAIN_KEY = 'sf_referrng_domain'

function referringDomain(referrer: string): string {
  const split = referrer.split('/')
  if (split.length >= 3) {
    return split[2]
  }
  return ''
}

export async function pageView(isRetry: boolean = false): Promise<void> {
  // We have page load check on pingdom.
  if (isBot()) {
    return
  }

  try {
    if (!isRetry) {
      // Save first visited page to local storage,
      // we will set it as people property on signup.
      _saveFirstPage()
      // Save marketing URL.
      _saveMarketingUrl()
    }

    // Check if the user is logged in.
    const user = auth.getUser()
    if (!user) {
      // User has not signed up, collect page view, but don't track it yet.
      _collectEvent()
      return
    }

    // If mixpanel is not loaded yet, try later.
    //
    // Note: This should only be useful for pageView which we call
    // on page load in `router.afterEach`.
    //
    // Note: we set the `__MIXPANEL_LOADED` in the `loaded` callback
    // that we pass to `mixpanel.init`, see ../main.ts.
    if ((window as any).__MIXPANEL_LOADED !== true) {
      setTimeout(() => {
        pageView(true)
      }, 300)
      return
    }

    _setMixpanelUserPropertiesLoggedInUser()
    mixpanel.track('Page View')
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function register(user: AuthUser, done: () => void): void {
  if (!hasMixpanel()) {
    return
  }

  try {
    // This is the other half of the logic in _saveFirstPage().
    // Adds first visited page as people profile property.
    // We set this only once, on Signup.
    const firstPage = storage.getItem(FIRST_PAGE_KEY) || 'No first page'
    const marketingUrl =
      storage.getItem(MARKETING_URL_KEY) || 'No marketing url'
    const initialReferrer = storage.getItem(REF_KEY) || 'No referrer'
    mixpanel.people.set({
      Signup: new Date(),
      'First Page': firstPage,
      'Marketing URL': marketingUrl,
      'Initial Referrer SF': initialReferrer,
    })
    // Mixpanel docs say to call 'alias' on signup and 'identify' on login,
    // but it is not 100% clear what they mean:
    // - does it mean that the signup is followed up by login, so we need
    // to call both 'alias' and then 'identify'
    // - or these are separate processes (sinup results in login, but we still
    // only call 'alias' here)
    //
    // From the comment to the indentify method in the mixpanel js library code,
    // it looks like they mean the first scenario and we just need to make sure
    // that alias and identify calls are not sent at the same time
    // (without waiting for alias to complete):
    // >      * When used alone, mixpanel.identify will change the user's
    // >      * distinct_id to the unique ID provided. When used in tandem
    // >      * with mixpanel.alias, it will allow you to identify based on
    // >      * unique ID and map that back to the original, anonymous
    // >      * distinct_id given to the user upon her first arrival to your
    // >      * site (thus connecting anonymous pre-signup activity to
    // >      * post-signup activity). Though the two work together, do not
    // >      * call identify() at the same time as alias(). Calling the two
    // >      * at the same time can cause a race condition, so it is best
    // >      * practice to call identify on the original, anonymous ID
    // >      * right after you've aliased it.
    //
    // But also, the `alias` implementation calls the `identify`:
    //  https://github.com/mixpanel/mixpanel-js/blob/0174e23561d4394b6c08e7dedbc037b6298246a5/src/mixpanel-core.js#L1551-L1580
    //
    //  MixpanelLib.prototype.alias = function(alias, original) {
    //    ...
    //    return track('$create_alias', { 'alias': alias, 'distinct_id': original }, function() {
    //        // Flush the people queue
    //        _identify(alias);
    //    });
    //   ...
    // }
    //
    // And `mixpanel.alias` doesn't have the call back (see https://github.com/mixpanel/mixpanel-js/issues/57)
    //
    // Ideally, the code would look like this:
    //   mixpanel.alias(user.id, () => { _setMixpanelUserProperties(user) })
    //
    // We would call `identify` inside the `_setMixpanelUserProperties`, and
    // it would guarantee that it is invoked AFTER `alias` is completed
    // (otherwise, it can create a race condition on mixpanel side and result
    // in a duplicate profile).
    //
    // Since we can't call `identify` manually after, we rely on the default
    // implementation calling `identify` and instruct _setMixpanelUserProperties
    // to not call it:
    _setMixpanelUserProperties(user, false)

    // Now we exect `alais` to call `identify` on success and that will also send
    // all the people properties set by _setMixpanelUserProperties.
    mixpanel.alias(user.id)

    // Track a batch of page views collected before the singup.
    _trackCollectedEvents(() => {
      mixpanel.track('Signup', undefined, done)
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function login(user: AuthUser, done: () => void): void {
  if (!hasMixpanel()) {
    return
  }

  // We have login check on pingdom.
  if (isBot()) {
    return
  }
  try {
    mixpanel.people.set({
      'Last Login': new Date().toISOString(),
    })
    mixpanel.people.increment({
      Logins: 1,
    })
    // We need to call 'identify' after setting profile properties,
    // it is called inside the _setMixpanelUserProperties.
    _setMixpanelUserProperties(user)
    mixpanel.track('Login', undefined, done)

    // We only need to keep the history for users who didn't signup yet.
    // If the user logs in, it is safe to clear the history.
    // This may not work property if two different people are using the
    // same device / browser, but it should be rare enough to ignore and
    // better than having ever-growing history item in the local storage.
    _clearCollectedEvents()
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function logout(): void {
  if (!hasMixpanel()) {
    return
  }

  try {
    mixpanel.people.set({
      'Last Logout': new Date().toISOString(),
    })
    mixpanel.people.increment({
      Logouts: 1,
    })
    _setMixpanelUserPropertiesLoggedInUser()
    mixpanel.track('Logout')
    mixpanel.reset()
  } catch (error) {
    Sentry.captureException(error)
  }
}

/**
 * Track the event and optionally set people properties.
 *
 * Minimal example:
 *
 *   _track('My Event')
 *
 * Full example:
 *
 *   _track(
 *     // Event to track.
 *     'Select Book Category',
 *     // Event data.
 *     {
 *       category: tag,
 *     },
 *     // Data for mixpanel.people.set({..}).
 *     {
 *       'Last Book Category Select': new Date().toISOString(),
 *     },
 *     // Data for mixpanel.people.increment({..}).
 *     {
 *       'Book Category Selected': 1,
 *     },
 *     // Callback to execute after tracking.
 *     () => {
 *       console.log('done')
 *     }
 *   )
 */
export function _track(
  event: string,
  eventData?: Record<string, unknown>,
  peopleSetData?: Record<string, unknown>,
  peopleIncrementData?: Record<string, unknown>,
  callback?: (err?: any) => void,
): void {
  if (!hasMixpanel()) {
    return
  }

  try {
    if (peopleSetData) {
      mixpanel.people.set(peopleSetData)
    }
    if (peopleIncrementData) {
      mixpanel.people.increment(peopleIncrementData)
    }
    if (peopleSetData || peopleIncrementData) {
      // This should be after `mixpanel.people` calls (as it calls `identify`)
      // and before `track` (as it call `register`).
      _setMixpanelUserPropertiesLoggedInUser()
    }
    if (eventData || callback) {
      mixpanel.track(event, eventData || {}, callback)
    } else {
      mixpanel.track(event)
    }
  } catch (error) {
    Sentry.captureException(error)
    if (callback) {
      callback(error)
    }
  }
}

/**
 * Async version of the '_track' call.
 *
 * Note: this is better in terms of logic - most of the time we don't await the
 * tracking is it is not super essentaial, also this is not good for tests
 * - the calls left without wating for them and might be executed at
 * the point where the call will fail.
 * For example, in the trackBookReadTime test, where the tracking call is
 * invoked from the beforeRouteLeave it resulted in `book` parameter being
 * null (although we defenitely set it when we invoke the method).
 * Adding `async` helped solving it, but we still have many such cases with
 * other tracking calls.
 */
export async function _trackAsync(
  event: string,
  eventData?: Record<string, unknown>,
  peopleSetData?: Record<string, unknown>,
  peopleIncrementData?: Record<string, unknown>,
): Promise<void> {
  return await waitWithTimeout(
    (done) => {
      _track(event, eventData, peopleSetData, peopleIncrementData, done)
    },
    1,
    100,
  )
}

/**
 * Set Mixpanel super properties and people profile properties
 * for logged in user.
 */
export function _setMixpanelUserPropertiesLoggedInUser(): void {
  const user = auth.getUser()
  if (user) {
    _setMixpanelUserProperties(user)
  }
}

/**
 * Set Mixpanel super properties and people profile properties.
 *
 * Also calls 'identify' to actually send people profile changes to mixpanel.
 * This means, we should call this method after doing `mixpanel.people.xxx`
 * and before `mixpanel.track` (as this method also calls `register` to
 * set superproperties).
 */
export function _setMixpanelUserProperties(
  user: AuthUser,
  callIdentify: boolean = true,
): void {
  if (!hasMixpanel()) {
    return
  }

  const abData = experimentsData()
  // People profile properties.
  const properties: any = {
    $name: user.email,
    Email: user.email,
    Username: user.username,
    Plan: user.plan_id,
    Guid: user.id,
    Language: user.language,
    IsLimited: user.is_limited,
    ...abData,
  }
  mixpanel.people.set(properties)

  // Super properties, added to all "track" calls.
  // Note: those are the same as in people profile,
  // the difference is that super properties are added to events,
  // so we will track changes over time, while in the profile
  // we only have the latest static value.
  const superproperties: any = {
    $name: user.email,
    Email: user.email,
    Username: user.username,
    Plan: user.plan_id,
    Guid: user.id,
    IsLimited: user.is_limited,
    'Web App Title': APP_TITLE,
    'Web App Version': APP_VERSION,
  }
  mixpanel.register(superproperties)

  // https://developer.mixpanel.com/docs/javascript#section-storing-user-profiles
  // The Mixpanel library does not automatically create people profiles for any
  // user that performs an event. In order to send profile updates, you must call
  // mixpanel.identify in addition to mixpanel.people.set, which empowers you to
  // create profiles for only the users of your choice.
  if (callIdentify) {
    mixpanel.identify(user.id)
  }
}

/**
 * We want to save the first visited page to Mixpanel on signup.
 * We do it in a following way:
 * - On every page visit:
 *    - Check if we have first_page cookie set
 *    - If not: save window.location as first_page cookie
 * - On sign up
 *    - Set first_page cookie value as "First page seen"
 *    people profile property for logged in user.
 *
 * NOTE: this code also installed on Wordpress blog:
 *
 *   <!-- track first page -->
 *   <script type="text/javascript">
 *       const data = localStorage.getItem('sf_first_page');
 *       if (!data) {
 *        localStorage.setItem('sf_first_page', window.location.href);
 *        localStorage.setItem('sf_referrer', document.referrer || '$direct');
 *        localStorage.setItem(
 *          'sf_referrng_domain',
 *          referringDomain(document.referrer) || '$direct',
 *        );
 *      }
 *   </script>
 */
export function _saveFirstPage(): void {
  const data = storage.getItem(FIRST_PAGE_KEY)
  if (!data) {
    storage.setItem(FIRST_PAGE_KEY, window.location.href)
    storage.setItem(REF_KEY, document.referrer || '$direct')
    storage.setItem(
      REF_DOMAIN_KEY,
      referringDomain(document.referrer) || '$direct',
    )
  }
}

/**
 * Save marketing URL on page load.
 *
 * On the website page visit (www.shortform.com),
 * check if we have utm_source=xxx&utm_medium=partner parameters
 *
 * If the user is logged in: send a backend request with current URL.
 * Backend saves it (as user.marketing_url) and decides if the
 * discount is applicable.
 * If the user is not logged in: save it to the browser local
 * storage as marketing_url.
 *
 * Note: this is not directly related to mixpanel, but it is convenient
 * to handle marketing URL here, similar to the first url.
 */
export async function _saveMarketingUrl(): Promise<void> {
  const isMarketingUrl = window.location.search.indexOf('utm_source') > 0
  if (!isMarketingUrl) {
    return
  }

  const url = window.location.href
  const user = auth.getUser()
  if (user) {
    // For logged in user - send the URL to the backend.
    await request.post('billing/marketing_url', { marketing_url: url })
  } else {
    // For not logged in user - keep the first marketing_url
    // in the local storage (do not overwrite it).
    const data = storage.getItem(MARKETING_URL_KEY)
    if (!data) {
      storage.setItem(MARKETING_URL_KEY, url)
    }
  }
}

/**
 * Collect the page view to track it later, after signup.
 * This reduces mixpanel usage: we don't track users who
 * don't signup.
 *
 * NOTE: this code also installed on Wordpress blog:
 *
 *   <script type="text/javascript">
 *   function trackPageView() {
 *     const ua = window.navigator.userAgent.toLowerCase();
 *     if (ua.includes('pingdom')) {
 *         return;
 *     }
 *     const email = mixpanel.get_property('Email');
 *     if (email === undefined) {
 *       collectPageView();
 *       return;
 *     }
 *     mixpanel.track('Page View');
 *   };
 *   function collectPageView() {
 *     const storageData = localStorage.getItem('sf_history');
 *     const historyData = storageData ? JSON.parse(storageData) : [];
 *     const currentPage = window.location.href;
 *     const lastPage = historyData[historyData.length - 1];
 *     if (!lastPage || currentPage !== lastPage[1]) {
 *       historyData.push([Math.floor(new Date().getTime() / 1000), currentPage]);
 *     }
 *     localStorage.setItem('sf_history', JSON.stringify(historyData));
 *   };
 *   </script>
 *   <!-- start Mixpanel --><script type="text/javascript">
 *   // ...
 *   mixpanel.init("628b13b57f789d481577b13542b60034", {loaded: trackPageView});
 *   </script><!-- end Mixpanel -->
 */
export function _collectEvent(eventName?: string): void {
  try {
    const storageData = storage.getItem(HISTORY_KEY)
    const historyData = storageData ? JSON.parse(storageData) : []

    const currentPage = window.location.href
    const lastPage = historyData[historyData.length - 1]
    if (
      !lastPage ||
      !(currentPage === lastPage[1] && eventName === lastPage[2])
    ) {
      const data = [Math.floor(new Date().getTime() / 1000), currentPage]
      if (eventName) {
        data.push(eventName)
      }
      historyData.push(data)
    }
    storage.setItem(HISTORY_KEY, JSON.stringify(historyData))
  } catch (error) {
    Sentry.captureException(error)
    // Most likely the error is occured while parsing the history data,
    // so we clear it up and continue collecting from scratch.
    _clearCollectedEvents()
  }
}

/**
 * Track collected page views to mixpanel.
 *
 * This method is called on user signup.
 * It uses Mixpanel HTTP API to send the POST request with events,
 * to /track/ API method, the javascript SDK doesn't support the
 * batch call variant (alwyas sends GET request).
 *
 * See https://developer.mixpanel.com/docs/http#section-batch-requests
 *
 * See also mixpanel javascript SDK implementation, the `MixpanelLib.prototype._send_request`:
 * https://github.com/mixpanel/mixpanel-js/blob/f21586cf441b5461919bdba1d6257a2e9c084155/src/mixpanel-core.js#L882-L958
 *
 * and `MixpanelLib.prototype.track`:
 * https://github.com/mixpanel/mixpanel-js/blob/f21586cf441b5461919bdba1d6257a2e9c084155/src/mixpanel-core.js#L1050-L1134
 */
export function _trackCollectedEvents(done: () => void): void {
  if (!hasMixpanel()) {
    return
  }

  // The 'mixpanel' variable might be present, but if the adblocker or incognito
  // mode blocks mixpanel requests, it will not be intitialized and will not have
  // `get_config` method (as well as some other methods, like `get_property`, etc,
  // at the same time it will have some methods like `register`).
  if (typeof mixpanel.get_config === 'undefined') {
    return
  }

  try {
    // Get the collected data.
    const storageData = storage.getItem(HISTORY_KEY)
    const historyData = storageData ? JSON.parse(storageData) : []

    const initialReferrer = storage.getItem(REF_KEY) || '$direct'
    const initialReferringDomain = storage.getItem(REF_DOMAIN_KEY) || '$direct'
    // Overwrite referrer and referring domain super properties.
    mixpanel.register({
      $initial_referrer: initialReferrer,
      $initial_referring_domain: initialReferringDomain,
      // Note: to properly track `$referrer` and `$referring_domain`, we should
      // save them for each tracked event in historyData.
      // $referrer: referrer,
      // $referring_domain: referringDomain,
    })

    let previousEventURL = ''
    // Convert to array of Mixpanel events.
    const events = historyData.map((item: [number, string, string?]) => {
      // Event data structure: [
      //    number - timestamp
      //    string - page URL
      //    string - optional event name, 'Page View' by default
      // ]

      // The call below returns standard properties, for example:
      // {
      //  "$os": "Linux", "$browser": "Firefox",
      //  "$current_url": "http://localhost:8080/app",
      //  "$browser_version": 68,
      //  "$screen_height": 1026, "$screen_width": 1824,
      //  mp_lib: "web",
      //  "$lib_version": "2.29.0",
      //  time: 1565080782.652
      // }
      //
      // In general, this might not be 100% accurate (like the user might
      // change the screen resolution, or mixpanel library might be updated
      // in the middle of the events collection), but should be good enough
      // in most cases.
      //
      // Note: this is also a bit risky as we use the internal detail, this
      // method might be changed or removed or the method name uglified.
      // In that case we should start getting Sentry errors and will be able
      // to fix that.
      // In some cases, mixpanel._ might not be available (while `mixpanel` itself
      // is usable), it this case - just start with the empty dictionary.
      const properties = mixpanel._ ? mixpanel._.info.properties() : {}

      // Add token and distinct_id.
      properties['token'] = mixpanel.get_config('token')
      properties['distinct_id'] = mixpanel.get_distinct_id()

      // Replace `$current_url` and `time` with the data we have in local storage.
      properties['time'] = item[0]
      properties['$current_url'] = item[1]

      // Add super properties.
      properties['Email'] = mixpanel.get_property('Email')
      properties['$name'] = mixpanel.get_property('$name')
      properties['Guid'] = mixpanel.get_property('Guid')

      // Set the referrer for the event.
      if (previousEventURL) {
        // If we have previousEventURL, use it as a referrer.
        properties['$referrer'] = previousEventURL
        properties['$referring_domain'] =
          referringDomain(previousEventURL) || '$direct'
      } else {
        // Use initial referrer (no previousEventURL, so this is the first event).
        properties['$referrer'] = initialReferrer || '$direct'
        properties['$referring_domain'] = initialReferringDomain || '$direct'
      }

      previousEventURL = item[1]

      const eventName: string = item[2] || 'Page View'

      return {
        event: eventName,
        properties: properties,
      }
    })
    _trackBatch(events, done)
    _clearCollectedEvents()
  } catch (error) {
    Sentry.captureException(error)
    // Execute done, so we aren't stuck waiting for callback if some error occured.
    done()
  }
}

export function _clearCollectedEvents(): void {
  storage.removeItem(HISTORY_KEY)
}

/**
 * Send the batch request to Mixpanel /track API.
 *
 * The data is strigified and base64 encoded, see
 * https://developer.mixpanel.com/docs/http#section-batch-requests:
 *
 * > To send a batch of messages to an endpoint,
 * > you should use a POST instead of a GET request.
 * > Instead of sending a single JSON object as the data query parameter,
 * > send a JSON list of objects, base64 encoded, as the data parameter
 * > of an application/x-www-form-urlencoded POST request body.
 *
 */
export function _trackBatch(
  events: Record<string, unknown>[],
  done: () => void,
): void {
  const encoded_data = btoa(JSON.stringify(events))

  _console_log('MIXPANEL BATCH REQUEST:')
  _console_log(events)

  let mixpanelApiHost = 'https://api-js.mixpanel.com'
  try {
    mixpanelApiHost = mixpanel.get_config('api_host')
  } catch (error) {
    Sentry.captureException(error)
  }

  axios
    .post(`${mixpanelApiHost}/track/?ip=1&verbose=1`, `data=${encoded_data}`, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then((response) => {
      _console_log('MIXPANEL RESPONSE:')
      _console_log(response.data)
      // Mixpanel always responds with 200 OK, status = 0 in the case of error
      // and status = 1 if everything is actually OK.
      if (response.data.status !== 1) {
        Sentry.captureMessage(
          'Mixpanel batch track error: ' + JSON.stringify(response.data),
        )
      }
    })
    .catch((error) => {
      Sentry.captureException(error)
    })
    .finally(function () {
      done()
    })
}

export function hasMixpanel(): boolean {
  if (typeof mixpanel !== 'undefined' && mixpanel) {
    return true
  }
  return false
}

export function _console_log(...data: any[]): void {
  // We can have 'mixpanel' object, but without the 'get_config' function when
  // mixpanel is not fully loaded yet.
  if (mixpanel && mixpanel.get_config && mixpanel.get_config('debug')) {
    /* eslint-disable no-console */
    console.log(data)
    /* eslint-enable no-console */
  }
}
