import { assertNotNull } from '@/helpers/typing'
import { ab } from './interface'
import { Experiment, OnboardingQuestionnaireVariant } from './types'

import { runTextUpdates } from '@/services/ab/13448_ab_experiment'
import { Doc } from '@/models/interfaces'
import { Sentry } from '@/services/sentry'
import { IS_PRERENDERING } from '@/init/settings'
import Cookies from 'js-cookie'
import { querySelectorWait } from '@/helpers/dom'
import { isContentTranslated, waitWeglotInitialization } from '../weglot'

/**
 * Initialize CloudFront experiments.
 *
 * Check A/B testing cookies set by CloudFront, send backend
 * requests and update frontend a/b data storage.
 */
export async function initAB(): Promise<void> {
  await newCopy13448Experiment(Experiment.new_copy_pdf_v2_13448, '/pdf')
  await aiGuideFlow13747()
  await homeRedesign15080()
}

/**
 * Feature flag to enable/disable Google Billing. Disabled by default.
 *
 * This is not a real A/B experiment, i.e. the experiment is not activated on
 * the backend.
 */
export function googleBillingEnabled(): boolean {
  return ab.experimentVariant(Experiment.google_billing_7517) === 'yes'
}

/**
 * Apply the text changes on the public pages
 *
 * @param {Experiment} experiment - The A/B experiment containing the text changes.
 * @param {string} allowedUrlExpression - A regular expression to match allowed URLs,
 * on which the text changes should be applied.
 */
export async function newCopy13448Experiment(
  experiment: Experiment,
  allowedUrlExpression: string,
): Promise<void> {
  const pathname = window.location.pathname
  const isTranslatedPage = pathname.includes(`/es${allowedUrlExpression}/`)

  if (!pathname.includes(allowedUrlExpression) || isTranslatedPage) {
    // Only start experiment on non-translated pdf pages
    return
  }

  let result = ab.experimentVariant(experiment)

  if (result === null) {
    result = await ab.start(experiment)
  }

  if (result === 'yes') {
    runTextUpdates(experiment)
  }
}

/**
 * Get the variant of the ai_label_13537 experiment.
 * Returns boolean:
 *   true - Show the ai label component
 *   false - Don't show the ai label component
 */
export function aiLabel13537ExperimentVariant(): boolean {
  return ab.experimentVariant(Experiment.ai_label_13537) === 'yes'
}

/**
 * Decide wheter or not to render the ai content label component in
 * the book preview and summary pages.
 *   true - Show the ai label component
 *   false - Don't show the ai label component
 */
export async function aiLabel13537Experiment(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.ai_label_13537)

  if (result === null) {
    result = await ab.start(Experiment.ai_label_13537)
  }

  return result === 'yes'
}

/*
 * Show the human book cover or the fallback on public summary pages:
 - A: Show human book cover
 - B: Show fall back book cover

 Within the main app we will only apply the experiment variant to one book (the one of the visited summary page).
 */
export async function aiCover13860Experiment(): Promise<void> {
  // Don't run the experiment while generating the static pages
  if (IS_PRERENDERING) {
    return
  }

  // Selection with waiting is needed to make this work locally with Vue.
  const fallbackCover = assertNotNull(
    await querySelectorWait('.fallback-cover'),
  )
  const humanCover = document.querySelector('.book-cover__image')

  if (!humanCover) {
    // Don't start the experiment if we don't have a human cover
    fallbackCover.classList.remove('opacity_cover_13860')
    return
  }

  let useFallbackCover = false

  await waitWeglotInitialization()
  const isTranslated = isContentTranslated()

  const cfVariant13747 = Cookies.get('X-AB-AI-Guide-Flow-13747')
  const localVariant13747 = ab.experimentVariant(Experiment.ai_guide_flow_13747)
  if (cfVariant13747 || localVariant13747 || isTranslated) {
    // The `ai_guide_flow_13747` experiment was started on CloudFront
    // Set excluded variant for this experiment
    // Note that we check for `ai_guide_flow_13747` experiment, but we exclude the user
    // from the (different) `ai_cover_flow_13860` experiment.
    // This is correct, we want to avoid the case where two experiments interact with
    // each other, see #14084 for details.
    //
    // Also, we don't start the ai_cover_flow_13860 experiment for the translated pages.
    await ab.start(Experiment.ai_cover_flow_13860, '_EXCLUDED_')
    // For translated pages we use the fallback cover.
    useFallbackCover = isTranslated
  } else {
    // Get the variant ("A" - human cover, "B" - automatic)
    let experimentVariant = ab.experimentVariant(Experiment.ai_cover_flow_13860)

    if (experimentVariant === null) {
      // Start the experiment
      experimentVariant = await ab.start(Experiment.ai_cover_flow_13860)
    }
    useFallbackCover = experimentVariant === 'B'
  }

  if (useFallbackCover) {
    // Hide human cover
    humanCover.classList.add('hide_cover_13860')
    // Show fallback cover
    fallbackCover.classList.remove('hide_cover_13860')
  }
  humanCover.classList.remove('opacity_cover_13860')
  fallbackCover.classList.remove('opacity_cover_13860')
}

/*
 * Check if we should show the fallback cover as part of the experiment ai_cover_flow_13860
 */
export function useFallbackCover_13860(doc: Doc): boolean {
  const experimentVariant = ab.experimentVariant(Experiment.ai_cover_flow_13860)
  return experimentVariant === 'B' && doc.is_first_doc
}

/*
 * Send a sentry message if we have an unexpected case.
 * The user is in the experiment, this is experiment book, but we do not have human cover.
 * In this case the book should not be in the experiment.
 */
export function checkCoverFlow_13860(doc: Doc): void {
  const experimentVariant = ab.experimentVariant(Experiment.ai_cover_flow_13860)

  if (experimentVariant !== null && doc.is_first_doc && !doc.cover_image) {
    Sentry.captureMessage(
      `Unexpected case in experiment ${Experiment.ai_cover_flow_13860}: Book [${doc.url_slug}] doesn't have a cover but is in the experiment.`,
    )
  }
}

/**
 * Toggle the Link integration with Stripe.
 * Returns boolean:
 *   true - Enable Stripe Link
 *   false - Disable Stripe Link
 */
export async function stripeLinkIntegration13752Experiment(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.stripe_link_integration_13752)

  if (result === null) {
    result = await ab.start(Experiment.stripe_link_integration_13752)
  }

  return result === 'yes'
}

/*
 * Read experiment cookies set by CloudFront and transfer the experiment assignment to the local storage
 */
export async function aiGuideFlow13747(): Promise<void> {
  const cfVariant = Cookies.get('X-AB-AI-Guide-Flow-13747')
  const localVariant = ab.experimentVariant(Experiment.ai_guide_flow_13747)

  if (cfVariant && !localVariant) {
    await ab.start(Experiment.ai_guide_flow_13747, cfVariant)
  }
}

/**
 * Decide which plan card component to render on the billing page.
 * Returns boolean:
 *   true - Use the redesigned exercise page
 *   false - Don't use the redesigned exercise page
 */
export async function redesignedExercisePage13941Experiment(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.redesigned_exercise_page_13941)

  if (result === null) {
    result = await ab.start(Experiment.redesigned_exercise_page_13941)
  }

  return result === 'yes'
}

/**
 * Decide whether or not to show the long onboarding flow:
 *
 *   A - Keep the old logic.
 *   B - Use the long onboarding flow
 *   C - Use the long onboarding flow, but skip the intro pages
 *   D - Use the long onboarding flow, but move signup after the onboarding questionnaire
 */
export async function longOnboarding15024Experiment(): Promise<OnboardingQuestionnaireVariant | null> {
  let result = ab.experimentVariant(Experiment.long_onboarding_15024)

  if (result === null) {
    result = await ab.start(Experiment.long_onboarding_15024)
  }

  return result as OnboardingQuestionnaireVariant | null
}

export function longOnboarding15024ExperimentVariant(): OnboardingQuestionnaireVariant | null {
  const variant = ab.experimentVariant(Experiment.long_onboarding_15024)
  return variant as OnboardingQuestionnaireVariant | null
}

export function isLongOnboardingVariant(
  variant: OnboardingQuestionnaireVariant | null,
): boolean {
  return variant === 'B' || variant === 'C' || variant === 'D'
}

/**
 * Decide whether or not to show the subscription price converted into the user's local currency.
 *   true - Add the converted subscription price to the plan card.
 *   false - Don't add the converted subscription price to the plan card.
 */
export async function localizedPricing14281Experiment(
  isExcluded: boolean = false,
): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.localized_pricing_14281)

  if (result === null) {
    if (isExcluded) {
      await ab.start(Experiment.localized_pricing_14281, '_EXCLUDED_')
      return false
    }

    result = await ab.start(Experiment.localized_pricing_14281)
  }

  return result === 'yes'
}

/**
 * Decide whether or not to allow copy text on the reading page:
 *   A - Keep the old logic.
 *   B - Allow copy text on the reading page.
 */
export async function allowCopyReadingPage14493(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.allow_copy_reading_page_14493)

  if (result === null) {
    result = await ab.start(Experiment.allow_copy_reading_page_14493)
  }

  return result === 'B'
}

/*
 * Get the home_redesign_15080 experiment variant from the CloudFront cookies.
 */
export async function homeRedesign15080(): Promise<void> {
  // Only run in the homepage and its variants.
  if (
    window.location.pathname !== '/' &&
    !window.location.pathname.startsWith('/home-15080-')
  ) {
    return
  }

  const cfVariant = Cookies.get('X-AB-Home-Redesign-15080')
  const localVariant = ab.experimentVariant(Experiment.home_redesign_15080)

  if (cfVariant && !localVariant) {
    await ab.start(Experiment.home_redesign_15080, cfVariant)
  }
}

/**
 * Show Stripe card or Stripe Payment element.
 * A - Stripe Card element
 * B - Stripe Payment element
 */
export async function stripePaymentOptions15213(): Promise<boolean> {
  const result = ab.experimentVariant(Experiment.stripe_payment_options_15213)

  // We don't start the experiment now.
  // if (result === null) {
  //   result = await ab.start(Experiment.stripe_payment_options_15213)
  // }

  return result === 'B'
}
